.cms-text {
  word-break: break-word;

  p a:not(.btn) {
    text-decoration: underline;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  ol,
  ul {
    margin-top: $grid-gutter-width * 0.5;
    margin-bottom: $grid-gutter-width * 0.5;
  }
}
