#cookies-notice {
  $width: 30rem;

  background: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
  z-index: $z-index-cookies;
  transition: 0.5s ease-out;

  &.is-loading {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  table {
    display: block;
    width: 100%;
    overflow: auto;
    border: 0;

    tr:first-child td {
      white-space: nowrap;
      background: $gray-400;
    }

    tr, td {
      border-color: $table-border-color;
      font-size: 0.75rem;
      padding: 5px 15px;
      text-align: left;

      p {
        text-align: left;
      }
    }

    tr:nth-child(odd):not(:first-child) td,
    tr:nth-child(odd):not(:first-child) th {
      background-color: $table-striped-bg;
    }
  }

  .cookie-message {
    transform: translate3d(-50%, -50%, 0);
    box-shadow: 0 2.2px 1.7px rgba(0, 0, 0, 0.022),
    0 5.3px 4px rgba(0, 0, 0, 0.028),
    0 10px 7.5px rgba(0, 0, 0, 0.03),
    0 17.9px 13.4px rgba(0, 0, 0, 0.033),
    0 33.4px 25.1px rgba(0, 0, 0, 0.039),
    0 80px 60px rgba(0, 0, 0, 0.06);
    width: $modal-md - 2px;
    max-width: 90%;

    @include media-breakpoint-up(md) {
      width: $width;
    }
  }

  .cookie-group {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
  }

  .modal-footer .btn,
  .buttons-wrapper .btn {
    @include media-breakpoint-only(xs) {
      width: 100%;
    }
  }

  .dropdown-details .dropdown-toggle {
    &::after {
      transform: translateY(2px);
    }

    &[aria-expanded="true"]::after {
      transform: translateY(2px) scale(-1);
    }
  }

  .modal-backdrop {
    opacity: 0;
  }
}
