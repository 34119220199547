* {
  @include font-smoothing(on);

  &::selection {
    color: $body-bg;
    background: lighten($primary, 10%);
  }
}

html {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 100%;
}

body {
  word-break: break-word;
}

main {
  flex-shrink: 0;
}

.container-fluid {
  max-width: $container-fluid-max-width;

  @include media-breakpoint-up(lg) {
    padding-left: $grid-gutter-width * 2;
    padding-right: $grid-gutter-width * 2;
  }
}

.bg-none {
  background: none !important;
}

abbr[title] {
  text-decoration: none;
  border: 0;
}
