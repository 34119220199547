.l-site-nav {
  $nav-breakpoint: 'lg';
  $offcanvas-width: 18.75rem;
  $offcanvas-top: 0; // could also be navbar height
  $offcanvas-bg-color: $black;
  $offcanvas-y: 2.5rem;
  $offcanvas-nav-link-padding-x: 0.625rem;
  $offcanvas-nav-link-padding-y: 1.625rem;
  $offcanvas-active-bg: $primary;

  position: relative;
  z-index: $zindex-offcanvas;

  .navbar-brand img {
    height: 2rem;
    max-width: 33vw;
  }

  .offcanvas {
    border-left: 0;

    @include media-breakpoint-up($nav-breakpoint) {
      position: static;
      visibility: visible !important;
      background: none;
      border: 0;
      transform: none;
      width: auto;
      margin-left: auto;
    }

    @include media-breakpoint-down($nav-breakpoint) {
      width: $offcanvas-width;
      background-color: $offcanvas-bg-color;
      padding-top: $offcanvas-y;
      padding-bottom: $offcanvas-y;
    }
  }

  .offcanvas .btn-close {
    @include media-breakpoint-up($nav-breakpoint) {
      display: none;
    }
  }

  .offcanvas .nav-link {
    @include media-breakpoint-down($nav-breakpoint) {
      padding: $offcanvas-nav-link-padding-x $offcanvas-nav-link-padding-y;

      &.active {
        background: $offcanvas-active-bg;
      }
    }
  }

  .navbar-languages {
    &::before {
      @include media-breakpoint-up($nav-breakpoint) {
        content: '';
        margin-left: $nav-link-padding-x;
        margin-right: $nav-link-padding-x;
        border-left: 1px solid $black;
        opacity: 0.5;
        height: 14px;
      }
    }

    .dropdown-menu {
      min-width: 0;
      left: -17px; // text alignment

      @include media-breakpoint-up(lg) {
        left: -9px; // text alignment
      }
    }
  }

  &:not(.navbar-dark) {
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background: $dark;
    }
  }

  &.navbar-dark {
    .navbar-languages::before {
      border-color: $white;
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background: $white;
    }
  }
}
