main#page-home {

  .navbar-brand {
    span {
      @include font-size(0);
    }
    img {
      width: 50vw;
      max-width: 300px;
    }
  }

  [class*='col-'] {
    @include media-breakpoint-up(md) {
      height: 100vh;
    }
  }

  .content-block {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
      font-weight: 100;
      @include font-size(30px);
      color: $secondary;
    }
  }

}
