@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

// scss-docs-start theme-color-variables
$white:         #f7f4ed;
$primary:       #4a563c;
$secondary:     #B69A74;
$success:       $primary;


// scss-docs-start theme-colors-map
$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);

// Custom
$container-fluid-max-width: 125rem;
$z-index-cookies: 10000;

// Options
$enable-negative-margins: true;

// Spacing
$spacer: 0.625rem;
$spacers: (
  0: 0,
  1: $spacer,
  2: $spacer * 2,
  3: $spacer * 3,
  4: $spacer * 4,
  5: $spacer * 5,
  6: $spacer * 6,
  8: $spacer * 8,
  10: $spacer * 10,
  12: $spacer * 12,
);

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $white;
$body-color:                $primary;


// Links

$link-color:                              $primary;
$link-decoration:                         none;
$link-hover-color:                        $secondary;
$link-hover-decoration:                   none;

// Grid columns
$grid-gutter-width: 2.5rem;
$container-padding-x: $grid-gutter-width * 0.625;
$gutters: $spacers;

// Typography
$font-family-sans-serif:      'Mundial', "Helvetica", "Arial", sans-serif;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  10: 0.625rem,
  12: 0.75rem,
  14: 0.875rem,
  16: 1rem,
  18: 1.125rem,
);

$headings-margin-bottom: $spacer * 2;

// Navbar
$navbar-dark-color: $white;
$navbar-light-color: $black;
